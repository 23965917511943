<template>
    <div class="agreement">
        <template v-if="agreementContent">
            <p
                class="vip-pop-ctxt02"
                style="padding:0 15px;height:395px;overflow-y:auto;cursor:default;text-align: left;text-indent: 2em;"
                v-html="agreementContent"
            ></p>
        </template>
        <template v-else>
            <p
                class="vip-pop-ctxt02"
                style="padding:0 15px;height:395px;overflow-y:auto;cursor:default;"
            >暂无内容</p>
        </template>
    </div>
</template>
<script>
export default {
    name: 'agreement',
    data() {
        return {
            agreementContent: ''
        };
    },
    created() {
        this.getAgreement();
    },
    methods: {
        getAgreement() {
            this.$api.getRegisterPrivacy().then(res => {
                if (res.data.length > 0) {
                    this.agreementContent = res.data[0].Content;
                }
            });
        }
    }
};
</script>
<style lang="scss" scoped>
.agreement > p {
    height: auto !important;
}
</style>
